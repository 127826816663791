<template>
  <div class="cart-list__promo-tag">
    <!-- TR-36378更新商品标签顺序为：QuickShip > FreeShipping > evoluSHEIN > 品类津 > 促销 -->
    <QuickShipTag
      v-if="showQuickShip"
      :class="[
        'cart-item__quick-ship',
        {
          'cart-item__quick-ship_mini': page == 'bag'
        }
      ]"
      :abt-info="abtParams"
      :language="language"
      :cart-qs-tag-info="cartQsTagInfo"
    />
    <div
      v-if="showFreeShipping"
      :class="['cart-item__free-ship', {'cart-item__free-omit': page == 'checkout'}]"
    >
      <s-label
        type="success"
        :class="['quick-ship-tag', {'ship-tag-omit': page == 'checkout'}]"
      >
        {{ language.SHEIN_KEY_PC_26630 }}
      </s-label>
    </div>
    <EvoluTag
      v-if="showEvoluShein"
      class="cart-item__evolu"
    />
    <VoucherTag
      v-if="isShowVoucherTag"
      :item="item"
      :language="language"
      :disable-click="isBatchActivePage"
      :append-to-body="page == 'bag' ? false : true"
    />
    <template v-if="showPromotionTag">
      <div
        v-if="![32].includes(promotionTagText.typeId)"
        v-tap="analysisData('1-7-3-16')"
        v-expose="analysisData('1-7-3-15')"
        class="cart-promotion-tag"
        :class="[tagClassName, {'club-gift-label': showClubGiftOnCheckout}]"
        :da-event-expose="[3, 10].includes(promotionTagText.typeId) ? '1-7-5-1' : ''"
        :data-typeId="promotionTagText.typeId"
        :data-isFullPromotion="0"
        :data-activity-id="promotionTagText.promotionId"
        :data-goods-sn="item.product.goods_sn"
        :data-is-member-gift="+isSheinClubGiftTag"
        @click="jump"
      >
        <span
          :da-event-expose="['bag', 'cart'].indexOf(page) > -1 && [10].includes(promotionTagText.typeId) ? '1-7-5-19' : ['cart'].indexOf(page) > -1 && item.product.insurable == 1 ? '1-7-5-21' : ''"
          :da-event-click="['bag', 'cart'].indexOf(page) > -1 && [10].includes(promotionTagText.typeId) ? '1-7-5-20' : ''"
          :data-is_flashsale="item.flashType"
          :data-goods_id="item.product.goods_id"
          class="tag-content"
        >
          <s-label
            v-if="showClubGiftOnCheckout"
            class="tag club-gift-font"
          >
            {{ language.SHEIN_KEY_PC_31103 }}
          </s-label>
          <s-label
            v-else
            :type="normalFlash ? 'default' : 'promo'"
            :class="[
              'tag',
              {
                'club-color': isSheinClubGiftTag,
                'flash-tag': normalFlash,
                'jump': canJump
              }
            ]"
          >
            {{ promotionTagText.typeIdText }}
          </s-label>
        </span>
        <!-- 促销标签提示icon tip -->
        <template v-if="promotionTagText.tipText && ['bag', 'cart'].indexOf(page) > -1">
          <s-popover
            :content="promotionTagText.tipText"
            :append-to-body="false"
            :placemen="page == 'bag' ? 'bottom' : 'bottom'"
            trigger="hover"
            :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px', whiteSpace: 'normal'}"
          >
            <template #reference>
              <sui_icon_info_12px_1
                class="tip-icon"
                size="12px"
              />
            </template>
          </s-popover>
        </template>
        <!-- 倒计时 -->
        <span
          v-if="timeStamp && soldoutIds.indexOf(item.id) == -1"
          class="has-timer-down"
        >
          <span
            :class="[
              'gray-color',
              isSheinClubGiftTag && 'club-color2',
            ]"
          >|&nbsp;</span>
          <ClientOnly>
            <template v-if="showDate">
              <span
                :class="[
                  'end-in-time',
                  isSheinClubGiftTag && 'club-color',
                ]"
              >{{ template(dateStamp, language.SHEIN_KEY_PC_30895) }}</span>
            </template>
            <count-down-time
              v-else
              class="time-down"
              :time-stamp="timeStamp"
              :show-type="'inline-promotion'"
            />
          </ClientOnly>
        </span>
        <span
          v-if="promotionTagText.typeIdIcon && canJump"
          class="tag-arrow"
          :class="arrowClass"
        >
          {{ promotionTagText.typeIdIcon }} </span>
      </div>

      <div
        v-else
        class="promotion-coupon"
        da-event-expose="1-7-5-1"
        :data-typeId="promotionTagText.typeId"
        :data-isFullPromotion="0"
        :data-activity-id="promotionTagText.promotionId"
        :data-goods-sn="item.product.goods_sn"
      >
        <EasyCoupon
          :coupon-code="promotionTagText.typeIdText"
          :is-new-style="true"
        />
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { template, timeTransformer } from '@shein/common-function'
import { MESSAGE_DATE } from '@/public/src/pages/common/biz_helper/timer_format/dictionary.js'
import { mapState, mapGetters } from 'vuex'
import EasyCoupon from 'public/src/pages/cart_new/components/getCoupon/EasyCoupon.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { sui_icon_info_12px_1 } from '@shein-aidc/icon-vue3'
import QuickShipTag from './QuickShipTag.vue'
import CountDownTime from 'public/src/pages/cart_new/components/CartCountDown.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import VoucherTag from './VoucherTag.vue'
import EvoluTag from './EvoluTag.vue'

export default {
  name: 'ProductPromoTag',
  components: {
    EasyCoupon,
    sui_icon_info_12px_1,
    QuickShipTag,
    CountDownTime,
    ClientOnly,
    VoucherTag,
    EvoluTag,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default(){
        return {}
      }
    },
    page: {
      type: String,
      default: ''
    },
    abtParams: {
      type: Object,
      default(){
        return {}
      }
    },
    freeLang: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    timeStamp: {
      type: [Number, String],
      default: ''
    },
    soldoutIds: {
      type: Array,
      default(){
        return []
      }
    },
    language: {
      type: Object,
      default(){
        return {}
      }
    },
    showQuickShip: {
      type: Boolean,
      default: false
    },
    showFreeShipping: Boolean,
    showClubGiftOnCheckout: {
      type: Boolean,
      default: false
    },
    isBatchActivePage: {
      type: Boolean,
      default: false
    },
    showEvoluShein: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(['cartQsTagInfo']),
    ...mapGetters([
      'isSuggested',
    ]),
    /**
     * @link wiki.pageId=880775279
     * @description 展示 promotion 标签的有1、9
     */
    promotionTagText () {
      // let result = { typeId: '', typeIdText: '' }
      if(this.item.promotion_type_id == '1000'){ // 下单页用券赠礼
        return { typeIdText: this.freeLang }
      }
      // if(this.item.promotionTag?.typeId == 3){
      //   return result
      // }
      return this.item.promotionTag || {}
    },
    showUnderPricePromotionTag() {
      return this.abtParams.UnderPrice?.param?.UnderPriceShow == 'A'
    },
    showPromotionTag (){
      if(this.item.promotionTag?.typeId == 30 && !this.showUnderPricePromotionTag){
        return ''
      }
      return this.promotionTagText.typeIdText
    },
    // 付费会员赠品 tag
    isSheinClubGiftTag() {
      return this.promotionTagText.typeId === 4 && [8, 14].includes(this.promotionTagText.promotionLogoType)
    },
    showDate() {
      if (this.isSheinClubGiftTag) return dayjs(this.timeStamp * 1000).diff(dayjs(), 'hour') > 72
      return false
    },
    dateStamp() {
      if (typeof window === 'undefined') return
      return timeTransformer({
        time: this.timeStamp * 1000,
        sDateMap: MESSAGE_DATE,
      })
    },
    canJump () {
      if (['bag', 'cart'].indexOf(this.page) === -1 || this.isBatchActivePage) return false
      return this.isSheinClubGiftTag || [3, 10, 30].indexOf(+this.promotionTagText.typeId) > -1
    },
    normalFlash() {
      return this.promotionTagText.typeId == 10 && [1, 4, 5].includes(Number(this.item.flashType))
    },
    tagClassName() {
      const res = []
      if (this.page === 'bag') res.push('mini-cart-tag')
      if (this.canJump) res.push('jump')
      if (this.normalFlash) {
        res.push(this.timeStamp ? 'is-flash-timer' : 'is-flash')
      } else {
        res.push(this.timeStamp ? 'is-other-timer' : 'is-other')
      }
      return res
    },
    arrowClass() {
      if (this.isSheinClubGiftTag) {
        return 'club-color'
      }
      if (this.timeStamp && this.soldoutIds.indexOf(this.item.id) == -1) {
        return 'gray-color' 
      }
      if (this.normalFlash) {
        return 'flash-tag'
      }
      return ''
    },
    isShowVoucherTag() {
      return this.page != 'checkout' || (this.page == 'checkout' && !this.isSuggested)
    }
  },
  methods: {
    template,
    jump(){
      if(!this.canJump) return
      [3, 10].indexOf(+this.promotionTagText.typeId) > -1 && daEventCenter.triggerNotice({
        daId: '1-7-5-2',
        extraData: {
          typeId: this.promotionTagText.typeId,
          isFullPromotion: 0,
          goods_sn: this.item.product.goods_sn,
          activity_id: this.promotionTagText.promotionId,
          is_member_gift: 0,
        }
      })
      if (this.isSheinClubGiftTag) {
        daEventCenter.triggerNotice({
          daId: '1-7-3-58',
          extraData: {
            goods_id: this.item.product.goods_id,
          }
        })
      }
      let url_prams = ''
      let info = this.analysisData()
      if(this.promotionTagText.typeId == 30) {
        url_prams = `tab=${this.promotionTagText.ruleNum}&ici=${this.page == 'bag' ? 'MiniCart' : 'PageCart'}&src_tab_page_id=${this.page == 'bag' ? '' : window.getSaPageInfo?.tab_page_id || ''}&src_module=${info.data?.src_module || ''}&src_identifier=${info.data?.src_identifier || ''}`
      }
      this.$emit('jump', this.promotionTagText, url_prams, info)
    },
    analysisData (id) {
      if (
        this.page == 'cart' && [30].includes(this.promotionTagText.typeId) ||
        this.promotionTagText.typeId === 10 && this.promotionTagText.flashType === 6 // 品牌特卖
      ) {
        const promotion_id = this.promotionTagText.promotionId
        const promotion_tp_id = this.promotionTagText.typeId
        const content_list = `underPrice_${this.promotionTagText.ruleNum || ''}`
        return {
          id: id,
          data: {
            promotion_id,
            promotion_tp_id,
            content_list,
            src_module: 'promotion',
            src_identifier: `on=${promotion_tp_id}\`cn=${promotion_id}\`ps=${this.index + 1}_1\`jc=${content_list}`,
            src_tab_page_id: this.page == 'bag' ? '' : typeof window != 'undefined' && window.getSaPageInfo?.tab_page_id || '',
          }
        }
      }
      return {}
    },
  },
  emits: ['jump']
}
</script>

<style lang="less" scoped>
.cart-promotion-tag{
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-top: 0;
  padding: 3px 6px;
  line-height: normal;
  &.mini-cart-tag{
    margin-top: 0;
    display: inline-flex;
  }
  .tag-arrow{
    color: @sui_color_promo_dark;
    display: inline-block;
    margin: 0 2px 0 4px;
    font-size: 12px;
    line-height: 1.2;
    cursor: pointer;
  }
  .flash-tag{
    // background: #FDF4D0 /*rw:#FFEDEB*/;
    background: unset;
    color: @sui_color_micro_emphasis;
    cursor: pointer;
    /*rw:begin*/
    color: @sui_color_flash;
  }
  .gray-color {
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
  .club-color {
    color: @sui_color_club_rosegold_dark1 !important; /* stylelint-disable-line declaration-no-important */
  }
  .club-color2 {
    color: @sui_color_club_rosegold_dark1 !important; /* stylelint-disable-line declaration-no-important */
    opacity: 0.4;
  }
  .tip-icon{
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    line-height: 1.3;
    // background: @sui_color_promo_bg;
    background: unset;
    padding: 0;
    margin-right: 4px;
    margin-left: 2px;
    color: #959595;
  }
  .tag-content{
    label{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      white-space: normal;
      line-height: 1.2;
      margin-right: 2px;
    }
    .club-gift-font {
      color: #FFFFFF;
    }
    .jump{
      cursor: pointer;
    }
  }
  &.jump {
    cursor: pointer;
  }
  &.is-flash {
    background: #FDF4D0;
    color: @sui_color_micro_emphasis;
    /*rw:begin*/
    background: #FFF7F0;
    color: @sui_color_flash;
  }
  &.is-flash-timer {
    background: linear-gradient(89.98deg, #FDF4D0 0.02%, rgba(253, 244, 208, 0.3) 100.8%);
    color: @sui_color_micro_emphasis;
    /*rw:begin*/
    background: linear-gradient(89.98deg, #FFE9E7 0.02%, #FFFAF7 100.8%);
    color: @sui_color_flash;
  }
  &.is-other-timer {
    background: linear-gradient(90deg, #FFF0EC 0%, rgba(255, 240, 236, 0.3) 100%), #FFFFFF;
    color: @sui_color_promo_dark;
  }
  &.is-other {
    background: @sui_color_promo_bg;
    color: @sui_color_promo_dark;
  }
  &.club-gift-label {
    background-color: #873C00;
    color: #FFFFFF;
  }
  .tag {
    background: unset;
    padding: 0;
    margin-right: 2px;
  }
}
.promotion-coupon {
  white-space: nowrap;
}
.cart-list__promo-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -4px;
  margin-right: -4px;
  margin-bottom: 8px;
  &>div[class] {
    margin-top: 4px;
    margin-right: 4px;
  }
}
.has-timer-down {
  display: flex;
  align-items: center;
  color: @sui_color_main;
  .end-in-time {
    color: @sui_color_promo_dark;
  }
  .new-down-time {
    margin-top: 0;
  }
  /* stylelint-disable-next-line */
  :deep(.c-count-down-time .time-content span) {
    /* stylelint-disable-next-line declaration-no-important */
    font-weight: normal !important;
  }
}
.quick-ship-tag {
  margin-right: 0;
}
.ship-tag-omit {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cart-item__quick-ship {
  margin: 4px 4px 0 0;
  max-width: 234px;
  font-size: 12px;
  &_mini {
    max-width: 100%;
  }
}
.cart-item__free-ship {
  display: inline-flex;
  margin: 4px 4px 0 0;
}
.cart-item__free-omit {
  width: 100%;
}
.cart-item__evolu {
  margin: 4px 4px 0 0;
}
</style>
